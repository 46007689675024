/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('fonts/source-sans-pro-v13-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/source-sans-pro-v13-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('fonts/source-sans-pro-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('fonts/source-sans-pro-v13-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/source-sans-pro-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
    url('fonts/source-sans-pro-v13-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/source-sans-pro-v13-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('fonts/source-sans-pro-v13-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('fonts/source-sans-pro-v13-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('fonts/source-sans-pro-v13-latin-italic.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('fonts/source-sans-pro-v13-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/source-sans-pro-v13-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('fonts/source-sans-pro-v13-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('fonts/source-sans-pro-v13-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/source-sans-pro-v13-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
    url('fonts/source-sans-pro-v13-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/source-sans-pro-v13-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('fonts/source-sans-pro-v13-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('fonts/source-sans-pro-v13-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('fonts/source-sans-pro-v13-latin-700italic.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Source Sans Pro';
  background-image: radial-gradient(
    farthest-side at 95% 90%,
    #f8f8f8 0%,
    #d1d1d1 100%
  );
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h2,
h3 {
  font-weight: normal;
}

p {
  margin: 0 0 1.4em 0;
}

.title-case {
  text-transform: capitalize;
}
